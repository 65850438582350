<!-- @format -->

<template>
  <b-card title="会员信息编辑">
    <validation-observer ref="VipEdit">
      <b-form>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员头像">
              <input type="file" placeholder="输入会员头像" ref="getImage" @change="changeInput">
              <img :src="avatarUrl" alt="加载失败" style="width: 60px; height: 60px; border-radius: 50%; box-shadow: 0 0 5px #ccc; display: inline-block">
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员邮箱">
              <b-form-input v-model="editInfo.email" placeholder="输入会员邮箱" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员名">
              <b-form-input v-model="editInfo.firstname" placeholder="输入会员名" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员姓">
              <b-form-input v-model="editInfo.lastname" placeholder="输入会员姓" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员电话">
              <b-form-input v-model="editInfo.mobile" placeholder="输入会员手机号" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员生日">
              <flat-pickr v-model="editInfo.birth" class="form-control invoice-edit-input" placeholder="选择日期" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员国家">
              <b-form-input v-model="editInfo.country" placeholder="输入会员国家" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员城市">
              <b-form-input v-model="editInfo.city" placeholder="输入会员城市" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员地址">
              <b-form-input v-model="editInfo.address" placeholder="输入会员住址" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员地址2">
              <b-form-input v-model="editInfo.address2" placeholder="输入会员住址2" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="邮政编码">
              <b-form-input v-model="editInfo.post_code" placeholder="输入邮政编码" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员ID">
              <b-form-input v-model.number="editInfo.new_id" placeholder="修改输入会员ID" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员密码">
              <b-form-input v-model="editInfo.password_hash" placeholder="输入会员新密码" />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员性别">

              <v-select
                :value="editInfo.gender"
                v-model="editInfo.gender"
                placeholder="请选择性别"
                :options="options"
                label="name"
                :reduce="(item) => item.gid"
              />
            </b-form-group>
          </b-col>
        </b-row> -->
        <!-- <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员地址">
              <b-form-input
                v-model="editInfo.address"
                placeholder="输入会员地址"
              />
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="上级会员">
              <b-form-input v-model.number="editInfo.pid" placeholder="输入上级会员id" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group label-cols="2" label-align="center" label="会员等级">
              <v-select v-model="editInfo.level_id" placeholder="请选择会员等级" :options="levelOptions" label="name" :reduce="item => item.id" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm" :disabled="isRequest"> 提交 </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { computed, onUnmounted, reactive, ref, getCurrentInstance } from '@vue/composition-api'
import { required, url } from '@core/utils/validations/validations'

import store from '@/store'
import 'flatpickr/dist/flatpickr.css'
import flatPickr from 'vue-flatpickr-component'
import { BButton, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BRow, BCard } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { useRouter, throttle } from '@core/utils/utils'
import axios from '@/libs/axios'
import VipManagerModule from '@/views/GeneralManagement/VipManager/List/VipManagerModule'

export default {
  name: 'VipEdit',
  components: {
    BFormRadioGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      observer: null,
      limit: 10,
      search: '',
      options: [
        {
          gid: 1,
          name: '男',
        },
        {
          gid: 2,
          name: '女',
        },
      ],
      levelOptions: [],
      vipOptions: [],
    }
  },
  computed: {
    filtered() {
      return countries.filter(country => country.includes(this.search))
    },
    paginated() {
      return this.filtered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.paginated.length < this.filtered.length
    },
  },
  created() {
    axios.get('/user/userLevelList').then(response => {
      this.levelOptions = response.data // 绑定数据！
    })
    let id = null
    axios.get('/order/create-user').then(res => {
      id = res.data.id
    })
    axios.get('/user/list').then(response => {
      for (let i = 0; i < response.data.list.length; i++) {
        if (response.data.list[i].id == id) {
          response.data.list.splice(i, 1)
        }
      }
      this.vipOptions = response.data.list
    })
  },
  created() {
    axios.get('/user/userLevelList').then(response => {
      this.levelOptions = response.data // 绑定数据！
    })
    let id = null
    axios.get('/order/create-user').then(res => {
      id = res.data.id
    })
    axios.get('/user/list').then(response => {
      console.log(response.data)
      for (let i = 0; i < response.data.list.length; i++) {
        if (response.data.list[i].id == id) {
          response.data.list.splice(i, 1)
        }
      }
      this.vipOptions = response.data.list
    })
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    topuser(val, loading) {
      console.log(val)
      loading(true)
      axios.get('/user/list', { params: { id: val } }).then(response => {
        loading(false)
        this.vipOptions = response.data.list
      })
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    sj() {
      console.log(123)
    },
  },
  computed: {
  avatarUrl() {
    if (this.editInfo.avatar && !this.editInfo.avatar.includes('http')) {
      return `https://api.myonewow.com/${this.editInfo.avatar}`;
    }
    return this.editInfo.avatar;
  },
},
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'vip-module'
    const { proxy } = getCurrentInstance()
    const editInfo = ref({})

    const { route, router } = useRouter()
    const isRequest = ref(false)
    const previmageRUL = ref('')
    const changeInput = (val = {}) => {
      const formData = new FormData()
      formData.append('image', val.target.files[0])
      // eslint-disable-next-line no-multi-assign
      previmageRUL.value = window.URL.createObjectURL(val.target.files[0])
      axios.post('/upload/uploadAvatar', formData).then(response => {
        editInfo.value.avatar = process.env.VUE_APP_BASE_URL.replace('/backend', '/') + response.data.url
      })
    }

    const VipEdit = ref(null)
    const validationForm = throttle(() => {
      if (!editInfo.value.avatar) {
        proxy.$bvToast.toast('头像不能为空')
      }
      VipEdit.value.validate().then(success => {
        console.log(success)
        // isRequest.value = true
        delete editInfo.value.created_at
        delete editInfo.value.sort
        delete editInfo.value.com_code
        editInfo.value.avatar = editInfo.value.avatar.replace(process.env.VUE_APP_BASE_URL.replace('/backend', '/'), '')

        // console.log(editInfo.value)
        // return
        store
          .dispatch('vip-module/editInvoices', { ...editInfo.value })
          .then(response => {
            if (response.code == 0) {
              proxy.$bvToast.toast(response.msg || '修改成功', {
                title: '提示',
                variant: 'success',
                solid: true,
                autoHideDelay: 2000,
              })
              const timer = setTimeout(() => {
                isRequest.value = false
                clearTimeout(timer)
                router.back()
              }, 500)
            } else {
              proxy.$bvToast.toast(response.msg || '修改失败', {
                title: '提示',
                variant: 'danger',
                solid: true,
                autoHideDelay: 2000,
              })
            }
          })
          .catch(() => {
            isRequest.value = false
          })
      })
    }, 0)
    function editDetail() {
      axios.get(`/user/detail?user_id=${proxy.$route.query.id}`, {}).then(response => {
        editInfo.value = response.data
        editInfo.value.password_hash = ''
        editInfo.value.new_id = response.data.id
        editInfo.value.avatar = response.data.avatar || `${process.env.VUE_APP_COM_URLNS}/static/default-avatar.png`
        // editInfo.value.pid = response.data.pid || '无上级会员'
      })
    }
    editDetail()

    return {
      validationForm,

      isRequest,
      editInfo,
      required,
      url,
      changeInput,
      previmageRUL,
      VipEdit,
    }
  },
}
</script>
<style>
.loader {
  text-align: center;
  color: #bbbbbb;
}
</style>
